import React from 'react'
import { css } from '@emotion/core'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import OutsideClickHandler from 'react-outside-click-handler'

const HamburgerButton = (props) => (
    <div
        css={css`
            display: inline-block;
            &, svg {
                width: 24px;
                height: 24px;
            }
        `}
        {...props}
    >
        <svg aria-hidden="true" focusable="false" className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path></svg>
    </div>
)

@observer class Hamburger extends React.Component {
    @observable stateOpen = false

    toggleOpen = (e) => {
        e.stopPropagation()
        this.stateOpen = !this.stateOpen
    }

    close = (e) => {
        this.stateOpen = false
    }

    render () {
        const { children } = this.props

        return (
            <>
                {this.stateOpen && (
                    <div css={css`
                        position: fixed;
                        z-index: 99;
                        left: 0; right: 0; top: 0; bottom: 0;
                        background-color: rgba(0, 0, 0, 0.33);
                    `}>

                    </div>
                )}
                <div css={css`
                    position: relative;
                    z-index: 100;
                    user-select: none;
                    height: 24px;
                `}>
                    <OutsideClickHandler onOutsideClick={this.close}>
                        <HamburgerButton onClick={this.toggleOpen} />
                        <nav
                            onClick={this.toggleOpen}
                            style={{
                                display: this.stateOpen ? 'block' : 'none'
                            }}
                            css={css`
                                position: absolute;
                                top: 33px;
                                margin-top: 8px;
                                left: -8px;
                                height: auto;
                                background-color: #fff;

                                border-radius: 8px;
                                box-shadow: 0 0 30px rgba(0, 0, 0, 0.33);
                                padding: 24px 18px;

                                &::before {
                                    content: '';
                                    width: 15px;
                                    height: 15px;
                                    position: absolute;
                                    top: -6px;
                                    left: 18px;
                                    background: #fff;
                                    transform: rotateZ(45deg);
                                    transform-origin: center;
                                }
                            `}
                        >   
                            <div css={css`
                                width: fit-content;
                                max-width: 90vw;

                                white-space: nowrap;

                                div, a {
                                    display: block;
                                    font-family: garamond-premier-pro-display;
                                    font-size: 20px;
                                    line-height: 1;
                                    letter-spacing: 1px;
                                    text-transform: uppercase;
                                    & + * {
                                        margin-top: 20px;
                                    }
                                }
                            `}>
                                {children}
                            </div>
                        </nav>
                    </OutsideClickHandler>
                </div>
            </>
        )
    }
}

export default Hamburger