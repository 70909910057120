/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css, Global } from "@emotion/core"

import Header from "./header"
import "./layout.css"
import Hamburger from "./hamburger"
import logo from '../images/ff-logo.png'

const LinkSection = (props) => {
  const [ state, setState ] = useState()

  const click = (e) => {
    e.stopPropagation()
    setState(!state)
  }

  return (
    <div
      style={{

      }}
    >
      <div onClick={click}>{props.title}</div>
      <div
        style={{
          display: state ? 'block' : 'none'
        }}
        css={css`
          margin-left: 1.5rem;
        `}
      >
        {props.children}
      </div>
    </div>
  )
}

const Layout = ({ children, stretch }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 600,
          height: stretch ? '100vh' : '',
          display: stretch ? 'flex' : '',
          flexDirection: stretch ? 'column' : ''
        }}
      >
        <Global styles={css`
          ::selection {
            background: hsla(243,50%,42%,0.6); /* WebKit/Blink Browsers */
            color: #fff;
          }
          ::-moz-selection {
            background: hsla(243,50%,42%,0.6); /* Gecko Browsers */
            color: #fff;
          }
        `}/>
        <header css={css`
          background-color: #fff;
          padding: 12px;
          a {
            text-decoration: none;
            color: inherit;
          }

          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (min-width: 648px) {
            padding: 12px 0;
          }
        `}>
          <Hamburger>
            <Link to='/art'>Art</Link>
            <LinkSection title='Collections'>
              <Link to='/becoming-spring-2022'>Spring ʼ22</Link>
              <Link to='/du-ciel-a-la-terre-fall-winter-21'>Fall/Winter ʼ21</Link>
            </LinkSection>
            <Link to='/studio'>Studio</Link>
            <Link to='/blog'>Blog</Link>
            <Link to='/about'>About</Link>
            <div css={css`
              margin-top: 40px !important;
              a {
                font-size: 14px;
              }
              a + * {
                margin-top: 14px;
              }
            `}>
              <a href='https://www.instagram.com/fuegofloresstudio/'>Instagram</a>
              <Link to='/legal'>Legal</Link>
            </div>
          </Hamburger>
          <Link to='/'>
            <img src={logo} css={css`
              display: block;
              height: 32px;
              margin: 0;
            `}/>
          </Link>
          <div style={{height: 24, width: 24}}></div>
        </header>
        <main style={{
          flex: stretch ? 1 : ''
        }}>
          {children}
        </main>
        <footer css={css`
          background-color: #fff;
          padding: 12px;
          a {
            text-decoration: none;
            color: inherit;
          }

          display: flex;
          justify-content: center;
          align-items: center;

          @media (min-width: 648px) {
            padding: 12px 0;
          }
        `}>
          &copy; Fuego &amp; Flores
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
